import React from 'react';

function Mission() {
    return(
        <div>
            <h1>Mission</h1>
        </div>
    )
}

export default Mission;