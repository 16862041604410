import React from 'react';

function Book() {
    return(
        <div>
            <h1>Book</h1>
            <iframe src="https://calendar.google.com/calendar/embed?src=bmac779%40gmail.com&ctz=America%2FChicago" style={{border: "0", width:"80vw", height:"80vh", frameborder:"0", scrolling:"no"}}></iframe>
        </div>
    )
}

export default Book;