import React from 'react';

function Tips() {
    return(
        <div>
            <h1>Tips</h1>
        </div>
    )
}

export default Tips;