import React from 'react';

function Testimonials() {
    return(
        <div>
            <h1>Testimonials</h1>
        </div>
    )
}

export default Testimonials;